import React, {Component} from 'react'
import {graphql, StaticQuery} from "gatsby";
import styled from "styled-components"
import { GatsbyImage } from 'gatsby-plugin-image';
import {media} from "utils/Media"
import Logo from "../shared/Logo"

const desktopVideoLink = "https://player.vimeo.com/progressive_redirect/playback/788211824/rendition/1080p/file.mp4?loc=external&signature=ea94d5360b7ad3b3b12a0c76d81b61f530eadb690b1a4cbbcb48b784a408c9d1"
const mobileVideoLink = "https://player.vimeo.com/progressive_redirect/playback/788211831/rendition/720p/file.mp4?loc=external&signature=320a15b4669238c27fe6fd26d581492ec96a24c513df762e82d6b151d4ff6eb3"

const HeaderSection = styled.div`
  position: relative;
  font-size: 0;

  #videobg {
    position: relative;
    z-index: 10;
    /* @media ${media.md} {
        max-height: calc(100vh - 65px);
      } */

    .fadeIn {
      opacity: 1 !important;
    }
    
    video {
      height: auto;
      width: 100%;
    }

    .desktop {
      display: none;
      position: relative;
      z-index: 10;
      @media ${media.md} {
        display: block;
        margin: 0 auto;
      }
    }

    .mobile {
      position: relative;
      z-index: 10;
      aspect-ratio: 575/672;

      @media ${media.md} {
        display: none;
      }
    }
  }

  .bgImage {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
    // z-index: 1;
    // opacity: 0;
    // transition-delay: .4s;
    // transition: all .4s ease;
    //
    // @media ${media.md} {
    //   max-height: calc(100vh - 65px);
    // }
  }

  /* .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
    }

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .embed-responsive-16by9 {
    &::before {
      padding-top: 56.25%;
    }
  } */

  .video {
    position: relative;
    height: 100%;

    .videobkg {

    }
  }
`

const VideoWrap = styled.div`
  background: #E4E3E3;
  width: 100%;
  height: 100%;
  /* max-height: calc(100vh - 85px); */
  display: none;

  .embed-responsive-item {
    width: 100% !important;
    height: 100% !important;
  }

  iframe {
    pointer-events: none;
  }

  @media ${media.md} {
    display: block;
  }
`

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            mobPlaying: false,
            image: true
        }
    }

    componentDidMount() {
        // this.checkVideo()
    }

    onReady = () => {
        this.setState({playing: true})
        this.setState({mobPlaying: true})
    }

    // checkVideo = () => {
    //     this.video.play();
    //     this.secondVideo.play()
    //     setTimeout(() => {
    //         this.setState({image: true})
    //         if (this.video.paused) {
    //             // this.video.play()
    //             this.setState({playing: false})
    //             this.setState({mobPlaying: false})
    //         } else {
    //             // this.video.pause()
    //             this.setState({playing: true})
    //             this.setState({mobPlaying: true})
    //         }
    //     }, 1000);
    // }

    render() {
        return (
            <HeaderSection>
                <div id="videobg">
                  <GatsbyImage
                    image={this.props.data.videoImageXl.childImageSharp.gatsbyImageData}
                    alt=""
                    className={`d-none d-md-block bgImage ${this.state.image ? "fadeIn" : ""}`}
                  />
                  <GatsbyImage
                    image={this.props.data.videoImageLg.childImageSharp.gatsbyImageData}
                    alt=""
                    className={` d-md-none bgImage ${this.state.image ? "fadeIn" : ""}`}
                  />
                  {/* <GatsbyImage
                    image={this.props.data.videoImageMd.childImageSharp.gatsbyImageData}
                    alt=""
                    className={`d-md-none bgImage ${this.state.image ? "fadeIn" : ""}`}
                  /> */}
                  {/* <div className="desktop">
                      <video playsInline muted loop ref={video => this.video = video}
                              style={{opacity: this.state.playing ? "1" : "0", transition: "all .5s ease"}}>
                          <source src={desktopVideoLink} type="video/mp4"/>
                      </video>
                  </div>
                  <div className="mobile">
                      <video playsInline muted loop ref={secondVideo => this.secondVideo = secondVideo}
                              style={{opacity: this.state.mobPlaying ? "1" : "0", transition: "all .5s ease"}}>
                          <source src={mobileVideoLink} type="video/mp4"/>
                      </video>
                  </div> */}
              </div>
            </HeaderSection>
        )
    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                videoImageXl: file(relativePath: { eq: "Grease_Generic_1920x780.jpg" }) {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 100)
                    }
                },
                videoImageLg: file(relativePath: { eq: "Grease_Generic_750x675.jpg" }) {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 100)
                    }
                },
                # videoImageMd: file(relativePath: { eq: "518854655-grease_jul23_startrio_767x1024.jpg" }) {
                #     childImageSharp {
                #       gatsbyImageData(width: 1920, quality: 100)
                #     }
                # }
            }
		`}
        render={data => (
            <HomeHeader data={data} {...props}/>
        )}
    />
)

export default Query
