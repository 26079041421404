import React, {Component} from 'react'
import Slider from 'react-slick'
import styled from "styled-components"
import {media} from "utils/Media";

const QuotesWrapper = styled.div`
      padding-top: 3rem;
      width: calc(100% - 50px);
      margin: 0 auto;
  
  h3 {
    padding: 0 1rem;
    color: #B71314;
  }

  .slick-prev, .slick-next {
    @media ${media.md} {
      width: 40px;
      height: 40px;
    }
  }

  .slick-prev:before, .slick-next:before {
    color: ${props => props.theme.colors.red};
    @media ${media.md} {
      font-size: 40px;
    }
  }
`

class Quotes extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            adaptiveHeight: false,
            autoplay: true
        };

        return (
            <QuotesWrapper>
                <Slider {...settings}>
                    {this.props.data.map((item, i) => {
                        return <QuoteItem
                            key={i}
                            data={item}
                        />
                    })}
                </Slider>
            </QuotesWrapper>
        );
    }

}

export default Quotes

const QuoteItem = (props) => (
    <div className="carousel__item">
        <div className="carousel__item-content">

            <h3 className="mb-0">{props.data.title}</h3>
            <p className="alt knockout c-pink">{props.data.subtitle}</p>
        </div>
    </div>
)