import * as React from "react"
import Seo from "utils/Seo"
import Layout from "../components/Layout/Layout"
import {graphql, Link, StaticQuery} from "gatsby";
import styled from "styled-components"
import {Container} from "reactstrap";
import {media} from "utils/Media"
import Video from "../components/shared/Video";
import HomeHeader from "../components/HomePage/HomeHeader"
import Credits from "../images/HomePage/book-desktop.png"
import Quotes from "../components/HomePage/Quotes"

const ContainerStyled = styled(Container)`
  position: relative;
  padding: 1rem;
  padding-top: 1.5rem;
  color: white;
  text-align: center;
  z-index: 2;
  max-width: 1700px;
  margin: 0 auto;

  h4 {
    span {
      color: ${props => props.theme.colors.secondary};
      text-decoration: underline;
    }
  }

  @media ${media.sm} {
    padding: 2rem;
  }

  .red {
    color: ${props => props.theme.colors.secondary};
    font-weight: bold;
    text-transform: uppercase;
  }

  .credits {
    width: 75%;
    @media ${media.md} {
      width: 50%;
    }
  }

  .london {
    @media ${media.md} {
      width: 85%;
    }
  }
`

const Bg = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: ${props => props.theme.colors.white};
`

const Content = styled.div`
  .topFix {
    top: -10px;
  }

  h3 {
    padding: 0 1rem 2rem;
    color: black;
  }
h2 {
  text-align: center;
  font-size: 1.5rem!important;
  width: 95%;
  color: ${props => props.theme.colors.black};
  margin: 2rem auto 0rem auto;
  @media ${media.sm} {
    font-size: 1.85rem!important;
  }
}
  .text-black {
    color: black !important;
  }

  h4 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    font-family: ${props => props.theme.font.family.body};
    font-weight: 700;
    @media ${media.md} {
      font-size: 1.8rem !important;
    }
  }

  p {
    font-family: ${props => props.theme.font.family.body};
    font-weight: 500;
    font-size: 1.2rem !important;
    line-height: 1.5rem;
    @media ${media.md} {
      font-size: 1.5rem !important;
      line-height: 2rem;
    }
  }
`

const IndexPage = (props) => (
    <Layout>
        <Seo
            slug="/" title=""
        />
        <HomeHeader/>
        <Content>
            <div className="position-relative topFix">
                <Bg/>
                <ContainerStyled className="pt-4 pb-5 py-lg-5">

                    <Quotes data={props.data.photos.nodes}/>
                </ContainerStyled>
            </div>
            <img src={Credits}
                 className="d-block credits mx-auto mb-3 px-4"
                 alt=""
                 />
            <ContainerStyled className="narrow">
            <p>The world&rsquo;s best-loved musical is back! Grittier and more glamorous than ever before.</p>
<p>After a whirlwind summer romance, leather-clad greaser Danny and girl-next-door Sandy are unexpectedly reunited when she transfers to Rydell High for senior year. But can they survive the trials and tribulations of teenage life and find true love once more?</p>
<p>With its phenomenal score, bursting with hits including&nbsp;<strong className="red"><em className="red">Summer Nights</em></strong>,&nbsp;<strong className="red"><em>Greased Lightnin&rsquo;</em></strong>,&nbsp;<strong className="red"><em>Hopelessly Devoted to You&nbsp;</em></strong>and&nbsp;<strong><em>You&rsquo;re the One That I Want,&nbsp;</em></strong>this thrilling new version is brought to life by a fresh young cast who bring energy, vitality and passion to this ultimate musical classic.&nbsp;</p>
<p>Directed by Curve&rsquo;s Artistic Director Nikolai Foster reignite your passion and get ready for an explosion of summer loving.</p>
<p>So round-up your Burger Palace Boys, pick up your Pink Ladies and re-discover why Grease really is the one that you want this term!</p>
                <div className="pt-2">
                    <Video
                        videoId="Xs5jVkDSioA"
                        // title="Watch Trailer"
                        videoImage={props.data.videoImage}
                    />
                    <p>Trailer includes the cast of the London 2022 production.</p>
                </div>
            </ContainerStyled>
        </Content>
    </Layout>
)
const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                videoImage: file(relativePath: { eq: "1055-2-copy.png" }) {
                    childImageSharp {
                         gatsbyImageData(width: 800, quality: 100)
                    }
                }
                photos:allQuotesJson {
                    nodes {
                        title
                        subtitle
                    }
                }  
            }
		`}

        render={data => (
            <IndexPage data={data}/>
        )}
    />
)
export default Query
