import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import ReactPlayer from "react-player";
import { media } from "utils/Media";


const VideoStyles = styled.section`
  width: 100%;
`;
const PopupStyles = styled.div`
${props => props.open === true && css`
    opacity: 1!important;
    visibility: visible!important;
`}
opacity: 0;
    visibility: hidden;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  div {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .close {
    position: absolute;
    top: 8.5rem;
    right: 2rem;
    div {
      width: 30px;
      height: 3px;
      background-color: #fff;
      content: "";
      transform-origin: 12.5px;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    z-index: 9999;
    position: relative;
    max-height: 250px;
    @media ${media.sm} {
      width: 70%;
      height: 70%;
      max-height: 650px;
      max-width: 1000px;
    }
  }
`;
const Video = ({ videoId, videoImage }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <div onClick={() => setOpen(!open)}>
          <GatsbyImage
            image={getImage(videoImage)}
            alt=""
            placeholder="none"
            
          />
        </div>
      </div>
      <VideoPopup videoId={videoId} open={open} setOpen={setOpen} />
    </>
  );
};

export default Video;

const VideoPopup = ({ videoId, open, setOpen }) => {
  return (
    <PopupStyles className={open ? "active" : "inactive"} open={open ? true : false} onClick={() => setOpen(!open)}>
      <div className="close" onClick={() => setOpen(!open)}>
        <div></div>
        <div></div>
      </div>
      <ReactPlayer
        url={`https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=0&modestbranding=1&origin=https://staging.andjulietthemusical.co.uk`}
        controls={true}
        playing={open}
        width="100%"
        height="100%"
      />
    </PopupStyles>
  );
};
